import Layout from '../components/layout';
import SEO from '../components/seo';
import DeliveryPageContainer from '../components/DeliveryPageContainer/deliveryPageContainer';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const Delivery = ({ location }) => {
  const data = useStaticQuery(graphql`{
    strapiDelivery {
      deliveryInfo {
          place
          info
          id
        }
    }
  }`);

  return (
    <Layout location={location} headerIsStatic>
      <SEO title="Доставка" />
      <DeliveryPageContainer deliveryInfo={data.strapiDelivery.deliveryInfo} />
    </Layout>
  );
};

export default Delivery;
