import { DeliveryPageContainerStyle, DeliveryPageContainerH2 } from './deliveryPageContainer.module.css';
import H2 from '../Blocks/H2/H2';
import { DeliveryType } from '../../types/types';
import React, { useEffect } from 'react';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { vkPixelFindLocation } from '../../Utils/vkPixel';

const useStyles = makeStyles({
  root: {
    maxWidth: '800px',
  },
  media: {
    height: 140,
  },
});

interface DeliveryContainerProps {
  deliveryInfo: DeliveryType[];
}

const DeliveryPageContainer = ({ deliveryInfo }: DeliveryContainerProps) => {
  const classes = useStyles();

  useEffect(() => {
    vkPixelFindLocation();
  }, []);

  return (
    <div className={DeliveryPageContainerStyle}>
      <H2 className={DeliveryPageContainerH2}>Дoстaвкa</H2>

      <Box marginBottom="60px" maxWidth="800px">
        <iframe title="Карта доставки" style={{ border: 0 }} src="https://www.google.com/maps/d/embed?mid=1JaKFXpnILoJpbZIV9EKRAlNQvjgX245k" width="100%" height="480" />
      </Box>

      {deliveryInfo && deliveryInfo.map((d) => (
        <Card key={d.id} elevation={0} className={classes.root}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {d.place}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {d.info}
            </Typography>
          </CardContent>
          <Divider />
        </Card>
      ))}
    </div>
  );
};

export default DeliveryPageContainer;
